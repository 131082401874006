import React from "react";

const SelectField = ({ register, options, errors, onChange, classname, title, required,value, disabled}) => {
    let reg = register || {}
    const {[reg.name]: error} = errors || {};


    const renderOptions = (item, key) => {
        return <option key={key} value={item}>{item}</option>
    }
    
    const handleChange = (e) => {
        if(register && register.onChange) register.onChange(e)
        if(onChange) onChange(e)
    }

    return (
        <>
        <p className="text-input-label font-size-medium">
                {title}{" "}
                {required && <i className="required padding-right-smallest">*</i>}
        </p>
        <select {...register} value={value} onChange={handleChange} className={classname} disabled={disabled}>
            <option value="" className="font-size-medium">Select One</option>
            {options && options.map((item, key) => renderOptions(item, key) )}
        </select>
        {error && <p className='text-color-red font-bold'> {error.message} </p>}
        </>
    )
}

export default SelectField;
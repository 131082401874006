import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

const PaginationTable = (props) => {
  const { tableHeaders, tableData, tableFields,handleRedirect} = props;

  let [setColumn, setsortColumn] = useState(props.tableHeaders[0]);
  let [sortDirection, setsortDirection] = useState("dsc");
  const [paginationTableData, setPaginationTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [isPaginationRequired, setIsPaginationRequired] = useState(true);
  const [sortedData, setSortedData] = useState([]);
  // const [dataCount, setDataCount] = useState(0);

  const paginationData = (offset, data) => {
    let slice = [],
      length;
    slice = data.slice(offset, offset + 10);
    length = Math.ceil(data.length / 10);
    setPaginationTableData(slice);
    setPageCount(length);
  };

  useEffect(() => {
    // Clone the original tableData to sort without modifying the state directly
    const clonedData = [...tableData];

    if (sortDirection === "asc") {
      clonedData.sort((a, b) =>
        compareDateValues(a[setColumn], b[setColumn])
      );
    } else {
      clonedData.sort((a, b) =>
        compareDateValues(b[setColumn], a[setColumn])
      );
    }

    // Reorder the sortedData to place date values at the beginning and null values at the end
    const dateValues = clonedData.filter((item) => item[setColumn] !== null);
    const nullValues = clonedData.filter((item) => item[setColumn] === null);
    const orderedData = [...dateValues, ...nullValues];

    // Set the sorted data to the state variable
    setSortedData(orderedData);

    // Calculate the initial pagination
    let slice = orderedData.slice(0, 10);
    let length = Math.ceil(orderedData.length / 10);

    if (length === 1) setIsPaginationRequired(false);
    else setIsPaginationRequired(true);

    setPageCount(length);
    setPaginationTableData(slice);
  }, [tableData, sortDirection, setColumn]);

  const handlePageClick = (e) => {
    let selectedPage = e.selected;
    let offset = selectedPage * 10;

    // Update the pagination with the correct slice of sorted data
    const slice = sortedData.slice(offset, offset + 10);
    setPaginationTableData(slice);
  };


  const requestSort = (selectedColumnSort) => {
    setsortColumn(selectedColumnSort);
  
    // Clone the original tableData to sort without modifying the state directly
    const sortedData = [...tableData];
  
    if (sortDirection === "asc") {
      sortedData.sort((a, b) =>
        compareDateValues(a[selectedColumnSort], b[selectedColumnSort])
      );
      setsortDirection("dsc");
    } else {
      sortedData.sort((a, b) =>
        compareDateValues(b[selectedColumnSort], a[selectedColumnSort])
      );
      setsortDirection("asc");
    }
  
    // Update the pagination with the sorted data and apply offset for the current page
    const offset = 0; // Reset the offset to 0
    const slice = sortedData.slice(offset, offset + 10);
    const length = Math.ceil(sortedData.length / 10);
  
    if (length === 1) setIsPaginationRequired(false);
    else setIsPaginationRequired(true);
  
    setPageCount(length);
    setPaginationTableData(slice);
  };
  
  const compareDateValues = (valueA, valueB) => {
    if (valueA === valueB) {
      return 0; // Both values are the same, treat them as equal
    }
  
    if (valueA === null) {
      return -1; // ValueA is null, move it to the end
    }
  
    if (valueB === null) {
      return 1; // ValueB is null, move it to the end
    }
  
    const dateA = new Date(valueA);
    const dateB = new Date(valueB);
  
    if (isNaN(dateA) && isNaN(dateB)) {
      return 0; // Both values are invalid dates, treat them as equal
    } else if (isNaN(dateA)) {
      return 1; // ValueA is an invalid date, move it to the end
    } else if (isNaN(dateB)) {
      return -1; // ValueB is an invalid date, move it to the end
    }
  
    return dateA - dateB; // Compare valid date values normally
  };
  const showArrow = (data) => {
    if(setColumn === data)
      if(sortDirection === "dsc") return "arrowUp"
      else return "arrowDown"
    else return "arrow-hide"
  }

  return (
    <>
        <div className="padding-around no-padding-top long-column-list-container width-100 background-color-white">
              <table className="font-size-regular table-custom background-color-white margin-top-15-px">
                <thead>
                  <tr className="width-100">
                    {tableFields &&
                      tableFields.length > 0 &&
                      tableHeaders &&
                      tableHeaders.length > 0 &&
                      tableFields.map((data, index) => {
                          return (
                            <React.Fragment key={index}>
                              <th
                                className=""
                                onClick={() => requestSort(data)}
                              >
                                <i
                                  className={showArrow(data)}
                                ></i>
                                {tableHeaders[index]}
                              </th>
                            </React.Fragment>
                          );
                      })}
                  </tr>
                </thead>
                <tbody className="">
                  {paginationTableData !== undefined &&
                    tableFields !== undefined &&
                    paginationTableData.map((data, index) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            {tableFields.map((input, indx) => {
                            if(input === "id"){
                                return (
                                    <React.Fragment key={indx}>
                                      <td className={"text-wrap"}>
                                        <span className="text-link" onClick={() => handleRedirect(data)}>{data[input] ? data[input] : "null"}</span>
                                      </td>
                                    </React.Fragment>
                                  );
                                }
                                else{
                                  return (
                                    <React.Fragment key={indx}>
                                      <td className={"text-wrap"}>
                                        {data[input] ? data[input] : "null"}
                                      </td>
                                    </React.Fragment>
                                  );
                                }
                            })}
                          </tr>
                        </React.Fragment>
                      );
                    })}
                </tbody>
              </table>
            <div>
              {isPaginationRequired &&
                <ReactPaginate
                  previousLabel={"«"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              }
            </div>
        </div>
    </>
  );
};

export default PaginationTable;

import React from 'react';

const InformationToolTip = ({tooltipType}) => {
    return (
        <div className="infotooltip margin-top-smallest"><i className="fa fa-info-circle margin-left-smaller"></i>
            {tooltipType === "accountID" && <div className="infotooltiptext font-weight-normal">If you do not have or know this information reach out to your <b>Account Manager</b> or <b>Service Manager</b> for assistance. </div>}
            {tooltipType === "caseAPI" && <div className="infotooltiptext font-weight-normal">Juniper recommends using a generic integration user or distribution list and not a specific user email address</div>}
        </div>
    );
};

export default InformationToolTip;  
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../UserContextProvider';

const SideNavBar = () => {
    const history = useHistory()
    const userType = useContext(UserContext);
    const LIST = [{name:"Service API Onboarding Request Form",path:"/onboardingRequestForm",show:"I,E"},{name:"Search App Submissions",path:"/search",show:"I"}]
    const redirect = (path) => {
        history.push(path)
    }
    return (
        <>
    <div className='width-22 background-color-white padding-left padding-right border-right-grey padding-top-large'>
        <span className='font-size-large font-bold text-color-black '>
            ACCOUNT DASHBOARD
        </span>
        {LIST.filter((data) => data.show.indexOf(userType)!== -1).map((item,index) => {
            return(
            <div key={index} className="padding-around-10">
                <span className='font-size-medium text-color-black mouse-enabled' onClick={() => redirect(item.path)}>
                    {item.name}
                </span>
            </div>)
        })}
    </div>
    </> 
    );
};

export default SideNavBar;
import React from 'react';
import InputField from '../Container/Common/InputField';
import { useForm } from "react-hook-form";
import SelectField from '../Container/Common/SelectField';
import TextAreaField from '../Container/Common/TextAreaField';
import { useHistory } from 'react-router-dom';

function SearchOnboardingForm() {
    const history = useHistory()
    const { register, handleSubmit,reset} = useForm({})
 
    const onFormSubmit  = (data) => {
        history.push("/searchResult")
    }

    const handleReset = () => {
        reset({Keyword:"",status:"",companyBdm:"",techReviewer:"",daysSinceLastModified:""})
    }

  return (
    <div className='grid width-100 padding-around-10 no-padding-bottom no-margin-bottom no-padding-top no-padding-left'>
    <div className='col-12 background-color-white padding-around-10'>
        <span className='obf-header padding-around-10'>Search Service API Onboarding Request Form</span>
    <div className=''>
        <div className="grid padding-around no-margin-bottom">
                            <div className='width-50 col-12'>
                                <InputField
                                    title="Keyword"
                                    className="width-100"
                                    required={false}
                                    type="text" 
                                    register={register("Keyword")}    
                                    placeholder = "Type Here"                      
                                />
                            </div>
                        </div>

                        <div className="grid padding-around no-margin-bottom">
                            <div className='width-50 col-12'>
                                <SelectField
                                    title="Status"
                                    className="width-100"
                                    required={false}
                                    type="text" 
                                    register={register("status")}
                                    options = {["Closed","New","Reviewed"]}                                                                 
                                />
                            </div>
                        </div>


                        <div className="grid padding-around no-margin-bottom">
                            <div className='width-50 col-12'>
                                <TextAreaField
                                    title="Company BDM"
                                    className="width-100 height-90px"
                                    required={false}
                                    type="text" 
                                    register={register("companyBdm")}
                                    placeholder = "Type Here"                      
                                />
                            </div>
                        </div>

                        <div className="grid padding-around no-margin-bottom">
                            <div className='width-50 col-12'>
                                <TextAreaField
                                    title="Tech Reviewer"
                                    className="width-100 height-90px"
                                    required={false}
                                    type="text" 
                                    register={register("techReviewer")}
                                    placeholder= "Type Here"                               
                                />
                            </div>
                        </div>

                        <div className="grid padding-around no-margin-bottom">
                            <div className='width-50 col-12'>
                                <InputField
                                    title="Days since last modified"
                                    className="width-100"
                                    required={false}
                                    type="text" 
                                    placeholder= "Type Here"                               
                                    register={register("daysSinceLastModified")}                               
                                />
                            </div>
                        </div>
                

                        <div className="grid padding-around no-margin-bottom no-padding-top">
                            <button className='primary-juniper' onClick={handleSubmit(onFormSubmit)}>Search</button>
                            <button className='primary-juniper margin-left-1-em' onClick={handleReset}>Clear</button>
                        </div>              
                        </div>
                    </div>
   </div>
  )
}

export default SearchOnboardingForm
import React from 'react';
import { BrowserRouter as Router, Route, useHistory, withRouter } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import Routers from './Routers';

const oktaAuth = new OktaAuth({
  issuer: 'https://juniper.okta.com/oauth2/default',
  clientId: '0oa1r1b95ixgkZ2YA0h8',
  redirectUri: window.location.origin + '/login/callback'
});

const App = () => {
    const history = useHistory()

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
      history.push(toRelativeUrl(originalUri || '/', window.location.origin));
    };
  
    return (
      <div className='App'>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <Route path="/login/callback" component={LoginCallback}/>
          <Routers />
      </Security>
      </div>
    );
}

const AppWithRouterAccess = withRouter(App);

const RouterApp = () => {
    return (<Router><AppWithRouterAccess/></Router>);
}

export default RouterApp;

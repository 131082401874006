import React from "react"

const Header = () => {
    // const HEADERS = ["Overview", "Learn","Build", "Explore","Dashboard", "Support"]

    return (
        <header className=" height-75-px border-bottom-header">
            <div className="brand-app">
                <a href="http://juniper.net" className="brand"><img
                src="stencil/images/juniper-logo-dark.svg" alt="Juniper Networks" title="Juniper Networks" height="30"/></a>
                {/* <h1 className="app"><a href="/"><span className="brand-link"><b>ENG</b>NET</span></a></h1> */}
            </div>
                <div className="text-center"></div>
                {/* <div className="media">
                    <div className="media-left">
                        {HEADERS.map((item,index) => {
                            return(
                                <strong key={index} className="padding-around-25 font-size-medium text-color-black">{item}</strong>
                            )
                        })}
                    </div>
                </div> */}
        </header>
    )
}

export default Header
import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import {SecureRoute } from '@okta/okta-react';
import Profile from './Profile';
import Dashboard from './Dashboard';
import OnboardingRequestForm from './Component/OnboardingRequestForm';
import SearchOnboardingForm from './Component/SearchOnboardingForm';
import SearchResult from './Component/SearchResult';
import SideNavBar from './Container/Common/SideNavBar';
import { getUserDetails } from './Constants';
import UserContextProvider from './UserContextProvider';
import Header from './Container/Common/Header';

const Routers = () => {
    const {authState } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);
    const [userType, setUserType] = useState("")
    
    useEffect(() => {
      setUserInfo(getUserDetails(authState)) 
    },[authState])

  useEffect(() => {
    if(userInfo !== null){
        if(userInfo.email.endsWith("@juniper.net")) setUserType("I")
        else setUserType("E")
    }
    },[userInfo])

    const onBoardingFormComponent = () => {
        return(
            <>
            <Header></Header>
            <main className='main-page'>
                <SideNavBar />
                <OnboardingRequestForm />
            </main>
            </>
        )
    }
    const intialComponent = () => {
        return(
            <>
            <Header></Header>
            <main className='main-page'>
                {userType === "I" && <>
                <SideNavBar />
                <Dashboard />
                </>}
                {userType === "E" && <OnboardingRequestForm />}
            </main>
            </>
        )
    }
    const searchOnboardingFormComponent = () => {
        return(
            <>
            <Header></Header>
            <main className='main-page'>
                <SideNavBar />
                <SearchOnboardingForm />
            </main>
            </>
        )
    }
    const searchResultComponent = () => {
        return(
            <>
            <Header></Header>
            <main className='main-page'>
                <SideNavBar />
                <SearchResult />
            </main>
            </>
        )
    }
    return (
        <>
        <UserContextProvider>
          <SecureRoute path="/onboardingRequestForm" exact={true} component={onBoardingFormComponent}/>
          <SecureRoute path="/" exact={true} component={intialComponent}/>
          <SecureRoute path="/search" exact= {true} component = {searchOnboardingFormComponent}/>
          <SecureRoute path="/profile" component={Profile}/>
          <SecureRoute path="/dashboard" component={Dashboard}/>
          <SecureRoute path="/searchResult" component={searchResultComponent}/>
        </UserContextProvider>
        </>
    );
};

export default Routers;
import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useOktaAuth } from '@okta/okta-react';
import Alert from '../Container/Common/Alert';
import InputField from '../Container/Common/InputField';
import SelectField from '../Container/Common/SelectField';
import TextAreaField from '../Container/Common/TextAreaField';
import { yupResolver } from '@hookform/resolvers/yup';
import DateField from '../Container/Common/DateField';
import { OnboardingRequestFormSchema } from '../Validations/UserValidation';
import Popup from '../Container/Common/Popup';
import { useHistory } from 'react-router-dom';
import MultiSelectField from '../Container/Common/MultiSelectField';

const OnboardingRequestForm = () => {
    const { register, handleSubmit, formState:{ errors}, setValue} = useForm({
        mode: "onChange",
        defaultValues: {EoxApi: true, caseApi: false, AssetApi:false, ContractApi: false, QuoteApi: false ,apiClientAccess: ""},
        resolver: yupResolver(OnboardingRequestFormSchema)
      });
    const history = useHistory()
    const historyData = history.location.state
    const apiClientAccessOptionList = ["Juniper Service Case API","Juniper Service Asset API","Juniper Service Contract API","Juniper Service Quote API","Juniper Service EOX API"];
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState({});
    const [apiClientAccess, setApiClientAccess] = useState([])
    const [selectedAuthenticationMethod, setAuthenticationMethod] = useState("")
    const [uploadFileContent, setUploadFileContent] = useState("")
    // const [selectedReviewDoc, setReviewDoc] = useState(false)
    const [showHide, setShowHide] = useState(false)
    const [showErrorHide, setShowErrorHide] = useState(false)
    const [type, setType] = useState("CREATE")
    const [details, setDetails] = useState([])
    const [showOnClientAccess, setShowOnClientAccess] = useState({
        caseApi: false,
        AssetApi: false,
        ContractApi: false,
        QuoteApi: false,
        EoxApi: true
    })
    const [errorList,setErrorList] = useState({})
    const [payloadData, setPayloadData] = useState({})

    useEffect(() => {
        if(errors){
            setValue("EoxApi", showOnClientAccess.EoxApi)
            setValue("caseApi", showOnClientAccess.caseApi)
            setValue("AssetApi", showOnClientAccess.AssetApi)
            setValue("ContractApi", showOnClientAccess.ContractApi)
            setValue("QuoteApi", showOnClientAccess.QuoteApi)
            let temp = errors
            // if(!(selectedAuthenticationMethod === "OAuth2.0")) delete temp.authorization
            // if(!showOnClientAccess.EoxApi) {
            //     delete temp.environment
            //     delete temp.userID
                
            // }
            // if(!showOnClientAccess.caseApi) {
            //     delete temp.emailAlias
            //     delete temp.accountID
            //     delete temp.startIntegrationTesting
            // }
            if(Object.keys(temp).length === 0) temp = {}
            setErrorList(temp)
            }
    },[errors,showOnClientAccess,selectedAuthenticationMethod, setValue])

    useEffect(() => {
        if(details && details["apiClientAccess"]){
            let temp = []
            if(details["apiClientAccess"].indexOf("Case") > 0) temp.push("Juniper Service Case API")
            if(details["apiClientAccess"].indexOf("Asset") > 0) temp.push("Juniper Service Asset API")
            if(details["apiClientAccess"].indexOf("Contract") > 0) temp.push("Juniper Service Contract API")
            if(details["apiClientAccess"].indexOf("Quote") > 0) temp.push("Juniper Service Quote API")
            if(details["apiClientAccess"].indexOf("EOX") > 0) temp.push("Juniper Service EOX API")
            setApiClientAccess(temp)
        }
    },[details])

    useEffect(() => {
        if(historyData && historyData.viewDetails){
            setType("VIEW")
            setDetails(historyData.viewDetails)
        }else{
            setType("CREATE")
        }
    },[historyData])

    useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
          // When user isn't authenticated, forget any user info
          setUserInfo(null);
        } else {
          setUserInfo(authState.idToken.claims);
          // You can also get user information from the `/userinfo` endpoint
          /*oktaAuth.getUser().then((info) => {
            setUserInfo(info);
          });*/
        }
    }, [authState, oktaAuth]); // Update if authState changes

    const onSaveLI = (data) => {
        console.log(data);
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;
        let payload = {...data}
        payload.certificateFileUpload = uploadFileContent
        payload.apiClientAccess = apiClientAccess.toString()
        payload.createDate = today
        setPayloadData(payload)
        // payload.reviewDoc = selectedReviewDoc 
        fetch("https://jm6n6ywvpg.execute-api.us-west-2.amazonaws.com/items",{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authState.accessToken.accessToken}`
            },
            body: JSON.stringify(payload)
        }).then((response) => {
            return handleError(response)
        })
        .then(res => {
            setShowHide(true)
        })
        .catch(error => {
            setShowErrorHide(true)
            console.log(error)
        });
      };

    const handleError = async(response) => {
        if(response.ok && response.status === 200){
                return response.json()
                }
        else if(response.status === 204){
                return Promise.reject("No records found.")
        }
        else if(response.status === 500){
                return Promise.reject("Oops! Something went wrong please try after sometime.")
        }
        else {
                let res = await response.json();
                return Promise.reject(res)
        }
    }

    const onSubmit = () => {
        handleSubmit(onSaveLI)();
    }

    const handlePopupClose = () => {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;
        let payload = {
            "reuestorName": userInfo.email,
            "apiClient": apiClientAccess.toString(),
            "company": payloadData.companyName,
            "environment" : payloadData.environment,
            "createdDate" : today
        }
        fetch("https://jm6n6ywvpg.execute-api.us-west-2.amazonaws.com/email",{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authState.accessToken.accessToken}`
            },
            body: JSON.stringify(payload)
        }).then(res => {
            window.location.href="/"
            setShowHide(false)
        })
        .catch(error => {
            console.log(error)
        });
    }

    const handlePopupErrorClose = () => {
        setShowErrorHide(false)
    }

    const handleSelect = (e) => {
        setAuthenticationMethod(e.target.value)
    }

    const handleUploadFile = (event) => {
        const reader = new FileReader()
        reader.onload = handleFileLoad;
        reader.readAsText(event.target.files[0])
      }
      
    const handleFileLoad = (event) => {
        setUploadFileContent(event.target.result)
    }

    const handleApiClientAccess = (selectedValue) => {
        let caseApiVar = selectedValue.filter(item => item === "Juniper Service Case API").length !== 0 ? true : false
        let eoxApiVar = selectedValue.filter(item => item !== "Juniper Service EOX API").length === 0 ? false : true
        let AssetApiVar = selectedValue.filter(item => item === "Juniper Service Asset API").length !== 0 ? true : false
        let ContractApiVar = selectedValue.filter(item => item === "Juniper Service Contract API").length !== 0 ? true : false
        let QuoteApiVar = selectedValue.filter(item => item === "Juniper Service Quote API").length !== 0 ? true : false  
        setShowOnClientAccess({caseApi: caseApiVar, EoxApi: eoxApiVar, AssetApi: AssetApiVar ,ContractApi: ContractApiVar,QuoteApi: QuoteApiVar})
        setApiClientAccess(selectedValue)
        setValue("apiClientAccess", selectedValue.join(" "))
    }

    return (
                <div className='grid width-100 padding-around-10 no-padding-top no-padding-left no-padding-bottom no-margin-bottom'>
                    <div className='col-12 background-color-white padding-around-10'>
                        <span className='obf-header padding-around-10'>Service API Onboarding Request Form</span>
                        <div className=''>
                        <Alert />
                        <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                               {type==="CREATE" &&
                                    <MultiSelectField 
                                    options={apiClientAccessOptionList}
                                    onChange={handleApiClientAccess}
                                    title="API Client Requesting access to"
                                    required="true"
                                    register = {register("apiClientAccess")}
                                    errors={errorList}
                                    />
                                }
                                {type==="VIEW" &&
                                <MultiSelectField 
                                options={apiClientAccessOptionList}
                                onChange={handleApiClientAccess}
                                title="API Client Requesting access to"
                                required="true"
                                disable={true}
                                value={apiClientAccess}
                                />
                                }
                            </div>
                        </div>
                        <div className="grid padding-around no-margin-bottom">
                            <div className='width-100 col-12'>
                               {type==="CREATE" &&
                               <InputField
                                    title="Company Name"
                                    className="width-100"
                                    required={true}
                                    type="text" 
                                    register={register("companyName")}
                                    errors={errorList}
                                />}
                                {type === "VIEW" && 
                                <InputField
                                    title="Company Name"
                                    className="width-100"
                                    type="text" 
                                    value={details["companyName"]}
                                    disabled={true}
                                ></InputField>}
                            </div>
                        </div>
                        <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                               {type==="CREATE" &&
                                <InputField
                                    title="First Name"
                                    className="width-100"
                                    required={true}
                                    type="text" 
                                    register={register("firstName")}
                                    errors={errorList}
                                />}
                                {type === "VIEW" && 
                                <InputField
                                    title="First Name"
                                    className="width-100"
                                    type="text" 
                                    value={details["firstName"]}
                                    disabled={true}
                                ></InputField>}
                            </div>
                        </div>
                        <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                               {type==="CREATE" &&
                                <InputField
                                    title="Last Name"
                                    className="width-100"
                                    required={true}
                                    type="text" 
                                    register={register("lastName")}
                                    errors={errorList}
                                />}
                                {type === "VIEW" && 
                                <InputField
                                    title="Last Name"
                                    className="width-100"
                                    type="text" 
                                    value={details["lastName"]}
                                    disabled={true}
                                ></InputField>}
                            </div>
                        </div>
                        <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                               {type==="CREATE" &&
                                <InputField
                                    title="Phone Number"
                                    className="width-100"
                                    required={true}
                                    type="text" 
                                    register={register("phoneNumber")}
                                    errors={errorList}
                                />}
                                {type === "VIEW" && 
                                <InputField
                                    title="Phone Number"
                                    className="width-100"
                                    type="text" 
                                    value={details["phoneNumber"]}
                                    disabled={true}
                                ></InputField>}
                            </div>
                        </div>
                        {/* <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                               {type==="CREATE" &&
                                <InputField
                                    title="Email alias that Juniper can use when our system is NOT able to connect to your endpoint to post ‘publish SR’ or ‘publish LOV’ messages"
                                    className="width-100"
                                    required={true}
                                    type="text" 
                                    register={register("emailAlias")}
                                    errors={errorList}
                                    tooltipType={"caseAPI"}
                                    tooltip={true}
                                />}
                                {type === "VIEW" &&
                                <InputField
                                    title="Email alias that Juniper can use when our system is NOT able to connect to your endpoint to post ‘publish SR’ or ‘publish LOV’ messages"
                                    className="width-100"
                                    type="text" 
                                    value={details["emailAlias"]}
                                    disabled={true}
                                ></InputField>}
                            </div>
                        </div> */}
                        {/* <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                               {type==="CREATE" &&
                                <InputField
                                    title="User ID used in each API Request call (required for authorization) "
                                    className="width-100"
                                    required={true}
                                    type="text" 
                                    register={register("userID")}
                                    errors={errorList}
                                    tooltipType={"caseAPI"}
                                    tooltip={true}
                                />}
                                {type === "VIEW" && 
                                <InputField
                                    title="User ID used in each API Request call (required for authorization) "
                                    className="width-100"
                                    type="text" 
                                    value={details["userID"]}
                                    disabled={true}
                                ></InputField>}
                            </div>
                        </div> */}
                        <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                               {type==="CREATE" &&
                                <InputField
                                    title="If your company has purchased a Juniper CarePlus Service contract and you work with a Juniper Service Manager please provide the name of the Juniper Service Manager"
                                    className="width-100"
                                    required={false}
                                    type="text" 
                                    register={register("manager")}
                                    errors={errorList}
                                />}
                                {type === "VIEW" && 
                                <InputField
                                    title="If your company has purchased a Juniper CarePlus Service contract and you work with a Juniper Service Manager please provide the name of the Juniper Service Manager"
                                    className="width-100"
                                    type="text" 
                                    value={details["manager"]}
                                    disabled={true}
                                ></InputField>}
                            </div>
                        </div>
                        <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                               {type==="CREATE" &&
                                <InputField
                                    title="Account Manager"
                                    className="width-100"
                                    required={true}
                                    type="text" 
                                    register={register("accountManager")}
                                    errors={errorList}
                                />}
                                {type === "VIEW" && 
                                <InputField
                                    title="Account Manager"
                                    className="width-100"
                                    type="text" 
                                    value={details["accountManager"]}
                                    disabled={true}
                                ></InputField>}
                            </div>
                        </div>

                        {/* <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                                <span className='text-input-label font-size-medium'>You have reviewed all of the documentation, understood the architecture and model and understand the development that needs to be done</span>
                            </div>
                        </div>
                        <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                            <input
                                    className="margin-left-smaller obf-checkbox"
                                    type="checkbox"
                                    checked={details["reviewDoc"] || selectedReviewDoc ? true : false}
                                    disabled={type==="CREATE"? false: true}
                                    onChange={handleReviewDoc}
                                    errors={errors}
                                />
                            </div>
                        </div> */}
                        <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                               {type==="CREATE" && showOnClientAccess.caseApi &&
                                <InputField
                                    title="Email alias that Juniper can use when our system is NOT able to connect to your endpoint to post ‘publish SR’ or ‘publish LOV’ messages"
                                    className="width-100"
                                    required={true}
                                    type="text" 
                                    register={register("emailAlias")}
                                    errors={errorList}
                                    tooltipType={"caseAPI"}
                                    tooltip={true}
                                />}
                                {details["emailAlias"] && type === "VIEW" &&
                                <InputField
                                    title="Email alias that Juniper can use when our system is NOT able to connect to your endpoint to post ‘publish SR’ or ‘publish LOV’ messages"
                                    className="width-100"
                                    type="text" 
                                    value={details["emailAlias"]}
                                    disabled={true}
                                ></InputField>}
                            </div>
                        </div>
                        <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                               {type==="CREATE" && showOnClientAccess.caseApi && 
                                <InputField
                                    title="User ID used in each API Request call (required for authorization) "
                                    className="width-100"
                                    required={true}
                                    type="text" 
                                    register={register("userID")}
                                    errors={errorList}
                                    tooltipType={"caseAPI"}
                                    tooltip={true}
                                />}
                                {details["userID"] && type === "VIEW" && 
                                <InputField
                                    title="User ID used in each API Request call (required for authorization) "
                                    className="width-100"
                                    type="text" 
                                    value={details["userID"]}
                                    disabled={true}
                                ></InputField>}
                            </div>
                        </div>
                        <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                                <span className='text-input-label font-size-medium'>Onboarding Setup Information</span>
                                <p className='font-size-medium padding-top-large'>Given that information could be different between Non Production and Production environment, this form would need to be submitted twice. First submit for integration in Non Production Environment and when all development and testing is completed submit form again for Production Environment cutover if response to the information asked is going to be different (which we expect to be the case).</p>
                            </div>
                        </div>
                        <div>
                            <InputField type="hidden" register={register("EoxApi")} />
                            <InputField type="hidden" register={register("caseApi")} />
                        </div>
                        { <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                               {type==="CREATE" &&
                                <SelectField
                                    title="Information below being provided for: "
                                    required="true"
                                    register={register("environment")} 
                                    errors={errorList} 
                                    options={["Non Production Environment","Production Environment"]}/>}
                                {type==="VIEW" &&
                                <SelectField
                                    title="Information below being provided for: "
                                    disabled={true}
                                    options={["Non Production Environment","Production Environment"]}
                                    value={details["environment"]}
                                    ></SelectField>
                                }
                            </div>
                        </div>}
                        <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                               {type==="CREATE" &&
                                    <SelectField
                                    title="Authentication method you have chosen (must select one) "
                                    required="true"
                                    register={register("authenticationMethod")} 
                                    errors={errorList} 
                                    onChange={handleSelect}
                                    options={["OIDC.","OAuth2.0","Client Certificate"]}/>} 
                                {type==="VIEW" &&
                                    <SelectField
                                    title="Authentication method you have chosen (must select one) "
                                    disabled={true}
                                    value={details["authenticationMethod"]} 
                                    options={["OIDC.","OAuth2.0","Client Certificate"]}/>}
                            </div>
                        </div>
                        {(selectedAuthenticationMethod === "OIDC." || details["authenticationMethod"] === "OIDC.") &&
                        <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                               {type==="CREATE" &&
                                <TextAreaField
                                    title="ID token (OpenID Connect)"
                                    className="width-100 height-218px"
                                    required={true}
                                    type="text" 
                                    register={register("IDToken")}
                                    errors={errorList}
                                />}
                                {type==="VIEW" &&
                                <TextAreaField
                                    title="ID token (OpenID Connect)"
                                    className="width-100 height-218px"
                                    disabled={true}
                                    type="text" 
                                    value={details["IDToken"]}
                                />}
                            </div>
                        </div>}
                        {(selectedAuthenticationMethod === "Client Certificate") && type==="CREATE" &&
                        <>
                        <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                                <span className='text-input-label font-size-medium'>Public certificate: For certificate-based authentication, attach the certificate file.</span>
                                <div className='padding-top-large'>
                                    <input type="file" register={register("certificateFileUpload")} onChange={handleUploadFile} name="certificate" id="fileUpload" accept=".pem,.cer,.crt" className="margin-bottom-small"/>
                                </div>
                            </div>
                        </div>
                        <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                                <span className='text-input-label font-size-medium'>Please note that for Production environment, we need you to provide a CA issued certificate. However, for Non-Production environment, you may choose to provide Self-Signed certificates, please ensure that you provide us the complete Certificate Chain (Intermediates, Root certificates) which were used to sign the given self-signed certificate.</span>
                            </div>
                        </div>
                        </>}
                        { 
                        <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                               { (showOnClientAccess.AssetApi || showOnClientAccess.caseApi || showOnClientAccess.ContractApi || showOnClientAccess.QuoteApi) && type==="CREATE" &&
                                <TextAreaField
                                    title="Your Account IDs on Juniper Support CRM system that will be used via this API Channel "
                                    className="width-100 height-218px"
                                    required={true}
                                    type="text" 
                                    register={register("accountID")}
                                    errors={errorList}
                                    tooltipType={"accountID"}
                                    tooltip={true}
                                />}
                                {details["accountID"] && type==="VIEW" &&
                                <TextAreaField
                                    title="Your Account IDs on Juniper Support CRM system that will be used via this API Channel "
                                    className="width-100 height-218px"
                                    disabled={true}
                                    type="text" 
                                    value={details["accountID"]}
                                />}
                            </div>
                        </div>}
                        {(selectedAuthenticationMethod === "OAuth2.0" || details["authenticationMethod"] === "OAuth2.0")  &&
                        <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                               {type==="CREATE" &&
                                <InputField
                                    title="For OAuth2.0 as the chosen Authentication method, provide an email alias that Juniper can use to contact when the secret needs to be rotated (which would be on a yearly basis)."
                                    className="width-100"
                                    required={true}
                                    type="text" 
                                    register={register("authorization")}
                                    errors={errorList}
                                />}
                                {type==="VIEW" &&
                                <InputField
                                    title="For OAuth2.0 as the chosen Authentication method, provide an email alias that Juniper can use to contact when the secret needs to be rotated (which would be on a yearly basis)."
                                    className="width-100"
                                    disabled={true}
                                    type="text" 
                                    value={details["authorization"]}
                                />}
                            </div>
                        </div>}
                        {
                        <>
                        <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                               {(showOnClientAccess.EoxApi) && type==="CREATE" &&
                                <DateField
                                    title="Date when you would be ready to engage in integration testing in Non Production Environment "
                                    className="width-20"
                                    required={true}
                                    type="date" 
                                    register={register("startIntegrationTesting")}
                                    errors={errorList}
                                />}
                                {details["goLiveDate"] && type==="VIEW" &&
                                <DateField
                                    title="Date when you would be ready to engage in integration testing in Non Production Environment "
                                    className="width-20"
                                    disabled={true}
                                    type="date" 
                                    value={details["startIntegrationTesting"]}
                                />}
                            </div>
                        </div>
                        <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                               {(showOnClientAccess.EoxApi) && type==="CREATE" &&
                                <DateField
                                    title="Date when you expect to go live"
                                    className="width-20"
                                    required={true}
                                    type="date" 
                                    register={register("goLiveDate")}
                                    errors={errorList}
                                />}
                                {details["goLiveDate"] && type==="VIEW" &&
                                <DateField
                                    title="Date when you expect to go live"
                                    className="width-20"
                                    disabled={true}
                                    type="date" 
                                    value={details["goLiveDate"]}
                                />}
                            </div>
                        </div>
                        </>}
                        <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                               {type==="CREATE" &&
                                <TextAreaField
                                    title="Questions ?"
                                    className="width-100 height-218px"
                                    required={false}
                                    type="text" 
                                    register={register("questions")}
                                    errors={errorList}
                                />}
                                {type==="VIEW" &&
                                <TextAreaField
                                    title="Questions ?"
                                    className="width-100 height-218px"
                                    disabled={true}
                                    type="text" 
                                    value={details["questions"]}
                                />}
                            </div>
                        </div>
                        {type==="CREATE" &&
                        <>
                        <div className="grid padding-around no-margin-bottom no-padding-top">
                            <div className='width-100 col-12'>
                                <span className='text-input-label font-size-medium'>By submitting this form you understand that by using Juniper’s Support Service APIs, you are agreeing that you have read and accepted the 
                                <a href="https://support.juniper.net/support/legal/supportapitos/"> Service API Terms of Service</a>
                                (the “Terms”). Acceptance of these Terms forms a legally binding contract with Juniper.</span>
                            </div>
                        </div>
                        <div className="grid padding-around no-margin-bottom no-padding-top">
                            <button className='success' onClick={onSubmit}>Submit</button>
                        </div>
                        </>}
                        </div>
                    </div>
                    <div className='col-3 background-#f9f9f9 padding-around-10'>
                        <legend>
                            <span>
                            Submitter Details
                            </span>
                        </legend>
                        <p>
                            <strong className='font-size-medium text-color-black'>
                                Profile &amp; Company
                            </strong>
                            <br />
                            <span className='font-size-medium text-color-black'>
                                {userInfo.email || ""}
                            </span>
                        </p>
                        <p>
                            <strong className='font-size-medium text-color-black'>
                                Contact Information
                            </strong>
                            <br/>
                            <span className='font-size-medium text-color-black'>
                                {userInfo.name || ""}
                            </span>
                        </p>
                    </div>
                    {showHide && (
                        <Popup
                        content={
                            <>
                            <p className="font-size-20-px text-color-green font-bold padding-top padding-bottom flex flex-center ">
                                Successfully created.
                            </p>
                            <div className="display-inline-flex flex-center">
                                <button className="primary" onClick={handlePopupClose}>Close</button>
                            </div>
                            </>
                        }
                        />
                    )}
                    {showErrorHide && (
                        <Popup
                        content={
                            <>
                            <p className="font-size-20-px text-color-red font-bold padding-top padding-bottom flex flex-center ">
                                Unauthorized
                            </p>
                            <div className="display-inline-flex flex-center">
                                <button className="primary" onClick={handlePopupErrorClose}>Close</button>
                            </div>
                            </>
                        }
                        />
                    )}
                </div>
    );
};

export default OnboardingRequestForm;
import React from 'react';

const Alert = () => {
    return (
        <div className='padding-arround-10'>
            <div className="obf-alert obf-alert-info margin-top-large" >
                <p className='font-size-medium'> If interested in integrating with Juniper's Service APIs complete this form. The form submitted will be reviewed and you will be contacted shortly after submission. Juniper uses this information to enable your access to the protected APIs.</p>
            </div>
        </div>
    );
};

export default Alert;
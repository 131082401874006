import React from 'react';
import InformationToolTip from './InformationToolTip';

const InputField = ({title, required, register, errors,tooltipType,tooltip, ...props}) => {
    let reg = register || {}
    const {[reg.name]: error} = errors || {};
    return (
        <div className={register ? "padding-top" : ""}>
            <p className="text-input-label font-size-medium">
                {title}{" "}
                {tooltip && <InformationToolTip tooltipType={tooltipType}/>}
                {required && <i className="required padding-right-smallest">*</i>}
            </p>
            <div className="field-format">
                <input {...register} {...props}/>
            </div>
            {error && <p className='text-color-red font-bold'> {error.message} </p>}
        </div>
    );
};

export default InputField;
import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect, useState } from 'react'
import { getUserDetails } from './Constants';

export const UserContext = React.createContext();

const UserContextProvider = (props) => {
    const [userInfo, setUserInfo] = useState(null);
    const [userType, setUserType] = useState("")
    const { authState } = useOktaAuth();

    useEffect(() => {
        setUserInfo(getUserDetails(authState))
    },[authState])

    useEffect(() => {
        if(userInfo !== null){
            if(userInfo.email.endsWith("@juniper.net")) setUserType("I")
            else setUserType("E")
        }
    },[userInfo])

    return (
      <UserContext.Provider value={userType}>
          {props.children}
      </UserContext.Provider>
    )
  }
  
  export default UserContextProvider
import * as yup from "yup";

export const OnboardingRequestFormSchema = yup.object().shape({
    companyName: yup.string().required("Company Name is required field"),
    apiClientAccess: yup.string().required("Please select an item in the list."),
    firstName: yup.string().required("First Name is required field"),
    lastName: yup.string().required("Last Name is required field"),
    phoneNumber: yup.string().required("Phone Number is required field"),
    EoxApi: yup.boolean(),
    caseApi: yup.boolean(),
    accountManager: yup.string().required("Manager is required field"),
    emailAlias: yup.string().when("caseApi", { is: true, then: yup.string().required("Please fill in this field.")}),
    authenticationMethod: yup.string().required("Please select an item in the list."),
    userID: yup.string().when("caseApi", { is: true, then: yup.string().required("Please fill in this field.")}),
    accountID: yup.string().when(["EoxApi", "caseApi"], { 
        is: (EoxApi, caseApi) => EoxApi === false && caseApi === false, 
        then: yup.string(),
        otherwise: yup.string().required("Please fill in this field.")
    }),
    startIntegrationTesting: yup.string().when(["EoxApi", "caseApi"], { 
        is: (EoxApi, caseApi) => EoxApi === false && caseApi === false, 
        then: yup.string(),
        otherwise: yup.string().required("Please fill in this field.")
    }),
    goLiveDate: yup.string().when("EoxApi", { is: true, then: yup.string().required("Please fill in this field.")}),
    authorization: yup.string().when("authenticationMethod", { is: val => val === "OAuth2.0", then: yup.string().required("Please fill in this field.")}),
    IDToken:  yup.string().when("authenticationMethod", { is: val => val === "OIDC.", then: yup.string().required("Please fill in this field.")}),
    environment: yup.string().when(["EoxApi", "caseApi"], { 
        is: (EoxApi, caseApi) => EoxApi === false && caseApi === false, 
        then: yup.string(),
        otherwise: yup.string().required("Please fill in this field.")
    }),
})

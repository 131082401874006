import React from 'react';
import Multiselect from 'multiselect-react-dropdown';

const MultiSelectField = ({options,title,required,value,onChange,disable, register, errors}) => {
    let reg = register || {}
    const {[reg.name]: error} = errors || {};

const onSelect = (selectedList) => {
    onChange(selectedList)
}

const onRemove = (selectedList) => {
    onChange(selectedList)
}

    return (
        <>
        <p className="text-input-label font-size-medium">
        {title}{" "}
        {required && <i className="required padding-right-smallest">*</i>}
        </p>       
        <Multiselect
        isObject={false}
        options={options}
        selectedValues={value} // Preselected value to persist in dropdown
        onSelect={onSelect} // Function will trigger on select event
        onRemove={onRemove} // Function will trigger on remove event
        displayValue="name" // Property name to display in the dropdown options
        disable={disable}
        {...register}
        />  
         {error && <p className='text-color-red font-bold'> {error.message} </p>}        
        </>
    );
};

export default MultiSelectField;
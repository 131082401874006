import React, { useState, useEffect } from 'react';
import PaginationTable from '../Container/Common/PaginationTable';
import { ExportToCsv } from 'export-to-csv';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

const SearchResult = () => {
    const history = useHistory()
    const TABLE_HEADERS = ["ID","Company","Environment","Requestor Name","API Client","Status","Date Created","Last Modified"]
    const TABLE_FIELDS=["id","companyName","environment","firstName","apiClientAccess","questions","createDate","createDate"]
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [result, setResult] = useState([]);

    useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null);
        } else {
            setUserInfo(authState.idToken.claims);
            // You can also get user information from the `/userinfo` endpoint
            /*oktaAuth.getUser().then((info) => {
              setUserInfo(info);
            });*/
        }
    }, [authState, oktaAuth]); // Update if authState changes

    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    useEffect(() => {
        fetch("https://jm6n6ywvpg.execute-api.us-west-2.amazonaws.com/items", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authState.accessToken.accessToken}`
            }
        })
            .then(res => res.json())
            .then(
                (success) => {
                    setIsLoaded(true);
                    setResult(success.Items);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [authState.accessToken.accessToken])

    const handleRedirect = (data) => {
        history.push({
            pathname:"/onboardingRequestForm",
            state: {viewDetails: data, path: history.location}
        })
    }

    const downloadCSV = () => {
        let columnName = TABLE_HEADERS;
        columnName = columnName.map(function(x){ return x.replace(/([A-Z]+)/g, ' $1').toUpperCase(); })
        let data = []
        data = result.map(({id,companyName,environment,firstName,apiClientAccess,questions,startIntegrationTesting,goLiveDate}) => ({id,companyName,environment,firstName,apiClientAccess,questions,startIntegrationTesting,goLiveDate}))
        const options = { 
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true, 
            showTitle: false,
            filename: 'Search_Results',
            useTextFile: false,
            useBom: true,
            headers: columnName
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);
    }
    if (!userInfo) {
        return (
            <div>
                <p>Fetching user profile...</p>
            </div>
        );
    }
    else if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div className='full-height-page margin-left-large margin-top-large font-size-large'>Loading...</div>;
    } else {
        return(
            <div className=' full-height-page width-100 padding-around-10 no-padding-top  background-color-white'>
                <div className='col-12 background-color-white padding-around-10'>
                    <span className='obf-header padding-around-10'>Service API Onboarding Request Search Result</span>
                <div className='padding-around-10 margin-top no-margin-bottom'>
                    <span className='obf-small-header'>Download Search Results:</span>
                    <button className='text-link' onClick={downloadCSV}>CSV</button>
                </div>
                </div>
                <PaginationTable 
                    tableHeaders={TABLE_HEADERS}
                    tableFields={TABLE_FIELDS}
                    tableData={result}
                    handleRedirect={handleRedirect}
                />
            </div>
            )
        }
};

export default SearchResult;
import React from "react";
 
const Popup = props => {
  return (
    <>
    <div className="popup-box">
      <div className="box border-black-solid-1px">
        {props.content}
      </div>
    </div>
    </>
  );
};
 
export default Popup;

// export const get = (url) => {
//     return fetch(url)
// }

// export const post = (url,payLoad) => {
//   return fetch(url,{
//     method: 'POST',
//     headers: {
//       'X-CSRF-TOKEN' : csrfToken,
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(payLoad)
//   })
// }

export const getUserDetails = (authState) => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      return null
    } else {
      // setUserInfo(authState.idToken.claims);
      return authState.idToken.claims;
      // console.log(authState.accessToken)
      // You can also get user information from the `/userinfo` endpoint
      /*oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });*/
    }
}
